import { Fragment, useState } from "react";
import styled from "styled-components";
import web1 from "./assets/bad-website-1.png";
import web2 from "./assets/bad-website-2.png";
import web3 from "./assets/bad-website-3.png";
import logo1 from "./assets/logo1.png";
import logo2 from "./assets/logo2.png";
import logo3 from "./assets/logo3.png";

const Container = styled.div`
  padding: 10px;
  width: 80%;
  margin: 0 auto;
  height: 100%;
  font-family: "papyrus";
  margin-bottom: 50px;

  ${(props) =>
    props.isEasterEggShown &&
    `
    filter: blur(10px);
      background-color: tomato;
  `}

  @media only screen and (max-width: 1000px) {
    width: 100%;
  }
`;

const Header = styled.header`
  margin: 0 auto;
  padding: 20px;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  img,
  & > div {
    width: 50%;
  }

  img {
    margin-left: 15px;
    margin-right: 15px;
    max-height: 375px;
    max-width: 375px;
  }

  .Typist span {
    font-size: 1.3rem;
  }

  @media only screen and (max-width: 1000px) {
    width: 100%;

    img,
    & > div {
      width: 100%;
    }
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;

  @media only screen and (max-width: 1000px) {
    justify-content: center;
  }
`;

const Image = styled.img`
  width: 100vw;
  max-width: 30%;
  margin-right: 10px;
  margin-bottom: 10px;

  @media only screen and (max-width: 1000px) {
    max-width: 90%;
  }
`;

const App = () => {
  const websites = [web1, web2, web3];
  const logos = [logo1, logo2, logo3];
  const [hourlyRate, setHourlyRate] = useState(null);
  const [weeklyHours, setWeeklyHours] = useState(null);
  const [derivedData, setDerivedData] = useState(null);

  const handleRateChange = (event) => {
    event.preventDefault();
    const { value } = event.target;
    setHourlyRate(value);
  };

  const handleHoursChange = (event) => {
    event.preventDefault();
    const { value } = event.target;
    setWeeklyHours(value);
  };

  const handleCalculateClick = (event) => {
    setDerivedData({
      cost: weeklyHours * hourlyRate,
      hours: weeklyHours / 2,
    });
  };

  return (
    <Fragment>
      <Container>
        <marquee behavior="alternate" className="flash-color">
          Sale! I'm currently offering to work 50% of all billable hours!
        </marquee>

        <Header>
          <img
            src="https://ca.slack-edge.com/T92KHQ8KZ-UBYFD999U-826157b40f82-512"
            alt="dirtbag"
          />
          <div>
            <h1>Hi! I'm Kevin.</h1>
            <h2>I'm an awesome web designer...most of the time.</h2>
            <h4>Some things you should know about me:</h4>
            <ul>
              <li>I'm not superstitious, I'm just a little stitious.</li>
              <li>
                Strengths: I'm 50% Cat Dad, 50% Human Dad, and 100% Beer Dad
              </li>
              <li>
                Weaknesses: I work too hard, I care too much, and sometimes I
                can be too invested in my job
              </li>
            </ul>
          </div>
        </Header>
        <div>
          <h3>My Work</h3>
          <section style={{ padding: "5px", marginBottom: "15px" }}>
            <strong>Websites</strong>
            <ImageContainer>
              {websites.map((image) => {
                return <Image src={image} alt="bad-website" />;
              })}
            </ImageContainer>
          </section>
          <section style={{ padding: "5px", marginBottom: "15px" }}>
            <strong>Logos</strong>
            <ImageContainer>
              {logos.map((image) => {
                return <Image src={image} alt="bad-logo" />;
              })}
            </ImageContainer>
          </section>
        </div>
        <div>
          <h3>Rate Calculator</h3>
          <p>
            I made this calculator to help you figure out how much I will cost
            and how many hours you will actually get out of me. <br /> I like to
            be honest with my clients.
          </p>
          <div>
            <div>
              <label>Hours Needed: </label>
              <input
                type="number"
                onChange={handleRateChange}
                value={hourlyRate}
                min="20"
                max="1000"
              />
              <span>/week</span>
            </div>
            <div>
              <label>Hourly Rate: </label>
              <span>$</span>
              <input
                type="number"
                onChange={handleHoursChange}
                value={weeklyHours}
                min="0"
                max="1000"
              />
              <span>/hr</span>
            </div>
            <button onClick={handleCalculateClick}>Calculate</button>
            {derivedData && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  border: "1px solid blue",
                  padding: "5px",
                }}
              >
                <div>
                  <div>Weekly Cost: ${derivedData.cost}</div>
                  <div>Weekly Hours I Provide: {derivedData.hours}</div>
                </div>{" "}
                <div style={{ width: "300px", textAlign: "center" }}>
                  <button
                    style={{
                      color: "#fff",
                      background: "firebrick",
                      padding: "10px 15px",
                      height: "45px",
                      width: "150px",
                      cursor: "pointer",
                      borderRadius: "30%",
                      fontSize: "1.4rem",
                      textAlign: "30px",
                    }}
                    onClick={() => {
                      alert("Thank you for downloading The Kevin Virus.");
                    }}
                  >
                    Hire Me
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Container>
    </Fragment>
  );
};

export default App;
